import React from "react";
import UnitStatusIndicator from "./UnitStatusIndicator";
import ContextMenu from './ContextMenu';
import { useState, useEffect, useCallback } from "react";

const UnitContainer = ({ unitOpta, unitStatus, channelName }) => {

    const [opta, setUnitOpta] = useState(null);
    const [status, setUnitStatus] = useState(null);
    const [channelname, setChannelName] = useState(null);
    const [isContextMenuVisible, setContextMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        setUnitOpta(unitOpta);
    }, [unitOpta]);

    useEffect(() => {
        setUnitStatus(unitStatus);
    }, [unitStatus]);

    useEffect(() => {
        setChannelName(channelName);
    }, [channelName]);

    const handleButtonPress = (event) => {
        event.preventDefault();
        setMenuPosition({ x: event.clientX, y: event.clientY });
        setContextMenuVisible(true);
    };

    const handleOptionClick = useCallback((option) => {
        console.log('Option clicked:', option[1]);
        setContextMenuVisible(false);
    }, []);

    const handleClose = useCallback(() => {
        setContextMenuVisible(false);
    }, []);

    return (
        <div className="unitContainer" onContextMenu={handleButtonPress}>
            <UnitStatusIndicator unitStatus={status} />
            <div className="dataContainer">
                <div className="opta">{opta}</div>
                <div className="gruppe">{channelname}</div>
            </div>
            <ContextMenu
                x={menuPosition.x}
                y={menuPosition.y}
                isVisible={isContextMenuVisible}
                options={[
                    ['Sammelruf (An Alle)', 'A'],
                    ['Auftrag aufgehoben', 'E'],
                    ['Melden für Übernahme', 'C'],
                    ['Kommen über Draht', 'F'],
                    ['Wache an', 'H'],
                    ['Sprechaufforderung', 'J'],
                    ['Lagemeldung', 'L'],
                    ['Unerlaubte Statusfolge', 'U'], 
                    ['Status korrigieren', 'c'], 
                    ['Warten', 'o'], 
                    ['Verstanden', 'u'],
                ]}
                onOptionClick={handleOptionClick}
                onClose={handleClose}
            />
        </div>
    );
};
export default UnitContainer;