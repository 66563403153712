import React from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutButton = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem("user.session");
        localStorage.removeItem("user.userId");
        localStorage.removeItem("user.accessLevel");
        localStorage.removeItem("user.token");
        localStorage.removeItem("user.loggedIn");
        navigate("/login");
    };

    return (
        <button className="btn btn-danger" onClick={handleLogout}>Logout</button>
    );
};

export default LogoutButton;