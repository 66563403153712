import { useState, useEffect } from "react";
import UnitContainer from './components/UnitContainer';

const Dispatch = () => {
  const [activeGroup, setActiveGroup] = useState(localStorage.getItem('activeGroup') || 1);
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());
  const [selectedLeitstelle, setSelectedLeitstelle] = useState(localStorage.getItem('selectedLeitstelle') || '');
  const [radioGroups, setRadioGroups] = useState([]);
  const token = localStorage.getItem('user.token');

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setSelectedLeitstelle(localStorage.getItem('selectedLeitstelle'));
    const savedActiveGroup = parseInt(localStorage.getItem('activeGroup'), 10);
    setActiveGroup(savedActiveGroup);
  }, [localStorage.getItem('selectedLeitstelle'), localStorage.getItem('activeGroup')]);

  useEffect(() => {
    if (selectedLeitstelle) {
      const fetchRadioGroups = async () => {
        try {
          const response = await fetch(`https://api.bos-funkspiel.com/api/v1/dispatchingcenter/${selectedLeitstelle}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          const data = await response.json();
          setRadioGroups(data.radiogroup); // Assuming the response contains radio groups data
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchRadioGroups();
    }
  }, [selectedLeitstelle, token]);

  const renderChannels = (group) => {
    return group.channel.radiochannel.map(channel => (
      <UnitContainer key={channel.id} unitOpta={"NIFW WTM80-12ELW2     1A"} unitStatus={channel.id - 1} channelName={"F_WTM_1"} />
    ));
  };

  const handleTabClick = (groupNumber) => {
    setActiveGroup(groupNumber);
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-secondary">
        <div className="collapse navbar-collapse justify-content-center">
          <ul className="nav nav-pills">
            {radioGroups.map((group, index) => (
              <li className="nav-item" key={index}>
                <a className={`nav-link text-white ${activeGroup === index + 1 ? 'active' : ''}`} onClick={() => handleTabClick(index + 1)}>{group.name}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className="navbar-text text-white">
          {currentTime}
        </div>
      </nav>
      <div className="tab-content mt-4 container-fluid">
        {radioGroups.map((group, index) => (
          <div key={index} className={`tab-pane ${activeGroup === index + 1 ? 'active' : 'fade'}`}>
            <div className="row">
              {renderChannels(group)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dispatch;
