import { Routes, Route, Outlet } from "react-router-dom";
import VOIPFire from './VOIPFire/VOIPFire';
import Lardis from './Lardis/Lardis';
import Login from './Login/Login';
import SignUp from './SignUp/SignUp';
import Dashboard from './Dashboard/Dashboard';
import Dispatch from './Dispatch/Dispatch';
import DispatchUnit from './Dispatch/Unit';
import ProtectedRoutes from "./ProtectedRoutes";
import DefaultLayout from '../layouts/DefaultLayout';
import DispatchLayout from '../layouts/DispatchLayout';
import LardisLayout from '../layouts/LardisLayout';
import UnitAdmin from './UnitAdmin/UnitAdmin';
import MemberAdmin from './MemberAdmin/MemberAdmin';

const AppLayoutWrapper = () => (
    <DefaultLayout>
        <Outlet />
    </DefaultLayout>
);

const DispatcherLayoutWrapper = () => (
    <DispatchLayout>
        <Outlet />
    </DispatchLayout>
);

const LardisLayoutWrapper = () => (
    <LardisLayout>
        <Outlet />
    </LardisLayout>
);

const Views = () => {
    return (
        <Routes>
            <Route element={<AppLayoutWrapper />} >
                <Route path='/' element={<Login />} />
            </Route>
            <Route element={<AppLayoutWrapper />} >
                <Route path='/register' element={<SignUp />} />
            </Route>
            <Route element={<ProtectedRoutes requiredAccessLevel={1} />}>
                <Route element={<AppLayoutWrapper />} >
                    <Route exact path='/dashboard' element={<Dashboard />} />
                </Route>
                <Route element={<LardisLayoutWrapper />} >
                    <Route exact path='/lardis' element={<Lardis />} />
                </Route>
                <Route element={<AppLayoutWrapper />} >
                    <Route exact path='/voipfire' element={<VOIPFire />} />
                </Route>
                <Route element={<ProtectedRoutes requiredAccessLevel={2} />}>
                    <Route element={<DispatcherLayoutWrapper />} >
                        <Route exact path='/dispatch' element={<Dispatch />} />
                    </Route>
                    <Route element={<DispatcherLayoutWrapper />} >
                        <Route exact path='/units' element={<DispatchUnit />} />
                    </Route>
                    <Route element={<AppLayoutWrapper />} >
                        <Route exact path='/memberadmin' element={<MemberAdmin />} />
                    </Route>
                    <Route element={<AppLayoutWrapper />} >
                        <Route exact path='/unitadmin' element={<UnitAdmin />} />
                    </Route>
                </Route>
            </Route>
            <Route element={<AppLayoutWrapper />} >
                <Route path='*' element={<Login />} />
            </Route>
        </Routes>
    );
}

export default Views;
