import React, { useEffect, useState } from 'react';

const RadioHeader = () => {

    return (
        <div className="lardisHeader">
            <div className="home"><span className="fas fa-house fa-xl" /></div>
            <div className="calendar"><span className="fas fa-calendar-days fa-xl" /></div>
            <div className="settings"><span className="fas fa-gear fa-xl" /></div> 
            <div className="flag"><span className="fas fa-flag fa-xl" /></div> 
            <div className="phone"><span className="fas fa-phone fa-xl" /></div>  
            <div className="message"><span className="fas fa-message fa-xl" /></div>        
        </div>
    );
};

export default RadioHeader;


