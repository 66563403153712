import React from "react";
import { useState, useEffect, useRef } from "react";

const UnitStatusIndicator = ({ unitStatus }) => {

    const [statusBGColor, setStatusBGColor] = useState(null);
    const [statusColor, setStatusColor] = useState(null);
    const [colorFlash, setColorFlash] = useState(false);
    const [toggled, setToggled] = useState(false);

    useEffect(() => {
        changeColor(unitStatus);
    }, [unitStatus]);

    useEffect(() => {
        if (!colorFlash) return;

        const toggleInterval = setInterval(() => {
            toggleColors();
        }, 250);

        return () => clearInterval(toggleInterval);
    }, [colorFlash, toggled]);

    const changeColor = async (unitStatus) => {
        switch (unitStatus) {
            case 0:
                setStatusBGColor("#FF0000");
                setStatusColor("#FFFFFE");
                setColorFlash(true);
                break;
            case 1:
                setStatusBGColor("#FFFE7F");
                setStatusColor("#000000");
                setColorFlash(false);
                break;
            case 2:
                setStatusBGColor("#80FF00");
                setStatusColor("#000000");
                setColorFlash(false);
                break;
            case 3:
                setStatusBGColor("#FF7F01");
                setStatusColor("#FFFFFF");
                setColorFlash(false);
                break;
            case 4:
                setStatusBGColor("#FF0000");
                setStatusColor("#FFFFFE");
                setColorFlash(false);
                break;
            case 5:
                setStatusBGColor("#C0C0C0");
                setStatusColor("#000000");
                setColorFlash(true);
                break;
            case 6:
                setStatusBGColor("#8B8B8B");
                setStatusColor("#000000");
                setColorFlash(false);
                break;
            case 7:
                setStatusBGColor("#FFFFFE");
                setStatusColor("#000000");
                setColorFlash(false);
                break;
            case 8:
                setStatusBGColor("#0AFFFF");
                setStatusColor("#000000");
                setColorFlash(false);
                break;
            case 9:
                setStatusBGColor("#C0C0C0");
                setStatusColor("#000000");
                setColorFlash(false);
                break;
            default:
                setStatusBGColor("#343434");
                setStatusColor("#FFFFFF");
                setColorFlash(false);
                break;
        }
    }

    const toggleColors = async () => {
        var s = !toggled;
        setToggled(s);
        if(toggled) {
            setStatusBGColor(statusColor);
            setStatusColor(statusBGColor);
        } else {
            setStatusBGColor(statusBGColor);
            setStatusColor(statusColor);
        }
    }

    return (
        <div className="unitStatus" style={{ backgroundColor: statusBGColor }}>
            <p style={{ color: statusColor }}>
                {unitStatus}
            </p>
        </div>
    );
};
export default UnitStatusIndicator;