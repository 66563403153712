// ContextMenu.js
import React, { useEffect, useRef } from 'react';

const ContextMenu = ({ x, y, isVisible, options, onOptionClick, onClose }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible, onClose]);

  if (!isVisible) return null;

  return (
    <ul ref={menuRef} style={{ top: y, left: x, position: 'absolute', backgroundColor: 'white', border: '1px solid black', listStyle: 'none', padding: '10px', zIndex: 1000 }}>
      {options.map((option, index) => (
        <li style={{ textIndent: 0 }} key={index} onClick={() => {
          onOptionClick(option);
          onClose();
        }}>
          {option[0]}
        </li>
      ))}
    </ul>
  );
};

export default ContextMenu;
