import React from 'react';

function DispatchLayout({ children }) {

    return (
        <>
            <head>
                <meta charSet="utf-8" />
                <link rel="stylesheet" type="text/css" href="css/bootstrap.css" />
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css" integrity="sha512-SnH5WK+bZxgPHs44uWIX+LLJAJ9/2PkPKZ5QiAj6Ta86w+fsb2TkcmfRyVX3pBnMFcV7oQPJkl9QevSCWr3W6A==" crossOrigin="anonymous" referrerPolicy="no-referrer" />
                <link rel="stylesheet" type="text/css" href="css/app.css" />
                <link rel="stylesheet" type="text/css" href="css/dispatch.css" />
                <link rel="stylesheet" type="text/css" href="css/style_dispatch_unitcontainer.css" />
                <link rel="stylesheet" type="text/css" href="css/style_dispatch_unitstatusindicator.css" />
                <link rel="stylesheet" type="text/css" href="css/style_dispatch_radiocontainer.css" />
                <link rel="stylesheet" type="text/css" href="css/style_dispatch_radiosignalindicator.css" />
                <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min.js"></script>
                <script src="https://cdn.socket.io/socket.io-1.4.5.js"></script>
            </head>
            <body>
                {children}
            </body>

        </>
    );
};

export default DispatchLayout;