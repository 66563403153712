import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const MemberAdmin = () => {
  const [members, setMembers] = useState([]);
  const [newMember, setNewMember] = useState({ email: '', fullName: '', accessLevel: 0, password: '' });
  const [editMember, setEditMember] = useState(null);
  const [editMemberData, setEditMemberData] = useState({ email: '', fullName: '', accessLevel: 0 });
  const [showModal, setShowModal] = useState(false);
  const token = localStorage.getItem('user.token');

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await fetch('https://api.bos-funkspiel.com/api/v1/user', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const data = await response.json();
        setMembers(data.data); // Assuming the response contains a list of members
      } catch (error) {
        console.error('Error fetching members:', error);
      }
    };

    fetchMembers();
  }, [token]);

  const handleAddMember = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.bos-funkspiel.com/api/v1/user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newMember)
      });
      const data = await response.json();
      setMembers([...members, data]); // Add the new member to the list
      setNewMember({ email: '', fullName: '', accessLevel: 0, password: '' }); // Clear the form
      setShowModal(false); // Close the modal
    } catch (error) {
      console.error('Error adding member:', error);
    }
  };

  const handleEditMember = (member) => {
    setEditMember(member);
    setEditMemberData({ email: member.email, fullName: member.fullName, accessLevel: member.accessLevel });
  };

  const handleUpdateMember = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://api.bos-funkspiel.com/api/v1/user/${editMember.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(editMemberData)
      });
      const data = await response.json();
      setMembers(members.map(m => (m.id === editMember.id ? data : m))); // Update the member in the list
      setEditMember(null); // Clear the edit form
      setEditMemberData({ email: '', fullName: '', accessLevel: 0 }); // Clear the edit data
    } catch (error) {
      console.error('Error updating member:', error);
    }
  };

  const handleDeleteMember = async (id) => {
    try {
      await fetch(`https://api.bos-funkspiel.com/api/v1/user/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setMembers(members.filter(member => member.id !== id)); // Remove the member from the list
    } catch (error) {
      console.error('Error deleting member:', error);
    }
  };

  const handleaccessLevelChange = (e, memberData, setMemberData) => {
    const value = parseInt(e.target.value);
    const newaccessLevel = e.target.checked
      ? memberData.accessLevel | value
      : memberData.accessLevel & ~value;
    setMemberData({ ...memberData, accessLevel: newaccessLevel });
  };

  const isaccessLevelChecked = (accessLevel, value) => {
    return (accessLevel & value) === value;
  };

  return (
    <div className="container">
      <h1 className="my-4">Member Administration</h1>

      <Button variant="primary memberButton" onClick={() => setShowModal(true)}>
        Add Member
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleAddMember}>
            <div className="mb-3">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                value={newMember.email}
                onChange={(e) => setNewMember({ ...newMember, email: e.target.value })}
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Full Name"
                value={newMember.fullName}
                onChange={(e) => setNewMember({ ...newMember, fullName: e.target.value })}
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                value={newMember.password}
                onChange={(e) => setNewMember({ ...newMember, password: e.target.value })}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Access Level</label>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  value="1"
                  checked={isaccessLevelChecked(newMember.accessLevel, 1)}
                  onChange={(e) => handleaccessLevelChange(e, newMember, setNewMember)}
                />
                <label className="form-check-label">User</label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  value="2"
                  checked={isaccessLevelChecked(newMember.accessLevel, 2)}
                  onChange={(e) => handleaccessLevelChange(e, newMember, setNewMember)}
                />
                <label className="form-check-label">Dispatcher</label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  value="4"
                  checked={isaccessLevelChecked(newMember.accessLevel, 4)}
                  onChange={(e) => handleaccessLevelChange(e, newMember, setNewMember)}
                />
                <label className="form-check-label">Benutzer Verwalter</label>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  value="8"
                  checked={isaccessLevelChecked(newMember.accessLevel, 8)}
                  onChange={(e) => handleaccessLevelChange(e, newMember, setNewMember)}
                />
                <label className="form-check-label">Unit Verwalter</label>
              </div>
            </div>
            <button type="submit" className="btn btn-primary memberButton">Add Member</button>
          </form>
        </Modal.Body>
      </Modal>

      <div className="row">
        {members.map(member => (
          <div key={member.id} className="col-md-4 mb-4">
            <div className="card">
              <div className="card-body">
                {editMember && editMember.id === member.id ? (
                  <form onSubmit={handleUpdateMember}>
                    <div className="mb-3">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={editMemberData.email}
                        onChange={(e) => setEditMemberData({ ...editMemberData, email: e.target.value })}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Full Name"
                        value={editMemberData.fullName}
                        onChange={(e) => setEditMemberData({ ...editMemberData, fullName: e.target.value })}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Access Level</label>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          value="1"
                          checked={isaccessLevelChecked(editMemberData.accessLevel, 1)}
                          onChange={(e) => handleaccessLevelChange(e, editMemberData, setEditMemberData)}
                        />
                        <label className="form-check-label">User</label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          value="2"
                          checked={isaccessLevelChecked(editMemberData.accessLevel, 2)}
                          onChange={(e) => handleaccessLevelChange(e, editMemberData, setEditMemberData)}
                        />
                        <label className="form-check-label">Dispatcher</label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          value="4"
                          checked={isaccessLevelChecked(editMemberData.accessLevel, 4)}
                          onChange={(e) => handleaccessLevelChange(e, editMemberData, setEditMemberData)}
                        />
                        <label className="form-check-label">Benutzer Verwalter</label>
                      </div>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          value="8"
                          checked={isaccessLevelChecked(editMemberData.accessLevel, 8)}
                          onChange={(e) => handleaccessLevelChange(e, editMemberData, setEditMemberData)}
                        />
                        <label className="form-check-label">Unit Verwalter</label>
                      </div>
                    </div>
                    <button type="submit" className="btn btn-success memberButton">Save</button>
                    <button type="button" className="btn btn-secondary ms-2 memberButton" onClick={() => setEditMember(null)}>Cancel</button>
                  </form>
                ) : (
                  <div>
                    <h5 className="card-title">{member.fullName}</h5>
                    <p className="card-text">{member.email}</p>
                    <p className="card-text">Access Level: {member.accessLevel}</p>
                    <p className="card-text">Email Status: {member.emailStatus}</p>
                    <button className="btn btn-primary me-2 memberButton" onClick={() => handleEditMember(member)}>Edit</button>
                    <button className="btn btn-danger memberButton" onClick={() => handleDeleteMember(member.id)}>Delete</button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MemberAdmin;
