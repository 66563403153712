import { useState, useEffect } from "react";
import React from 'react';
import cfg from '../../frontendsettings.json';
import { useNavigate } from "react-router-dom";

const SignUp = () => {

  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const registerUser = async () => {
    if (username !== "" && password !== "") {
      try {
        const response = await fetch(cfg.connection.backend.protocol + "://" + cfg.connection.backend.host + ":" + cfg.connection.backend.port + cfg.connection.backend.endpoints.register, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            email: username,
            password: password
          })
        });

        if (response.ok) {
          const data = response;
          // Handle successful login, e.g., save token, navigate to another page
          console.log("Register successful", data);
          localStorage.removeItem("user.session");
          localStorage.removeItem("user.userId");
          localStorage.removeItem("user.accessLevel");
          localStorage.removeItem("user.loggedIn");
          localStorage.removeItem("user.token");
          navigate("/login"); // or any other page you want to redirect to
        } else {
          // Handle login failure, e.g., show error message
          const errorData = response;
          console.error("Register failed", errorData);
        }
      } catch (error) {
        console.error("Error during register", error);
      }
    }
  }

  useEffect(() => {
  }, [])

  return (
    <div className="login">
      <div id="logintop">
        <div id="loginheader">
          <h1>Registrieren</h1>
        </div>
      </div>
      <div className="form">
        <label>
          <span className="material-icons md-16">person</span>
        </label>
        <input
          type="text"
          placeholder="Username"
          onChange={(event) => {
            setUsername(event.target.value);
          }} />
        <label>
          <span className="material-icons md-16">key</span>
        </label>
        <input
          type="password"
          placeholder="Passwort"
          onChange={(event) => {
            setPassword(event.target.value);
          }} />
          Schon registriert? <a onClick={() => navigate("/login")}>&nbsp;HIER&nbsp;</a> anmelden!
          <button onClick={registerUser}>Registrierung abschicken</button>
      </div>
    </div>
  );
}

export default SignUp;