import React, { useEffect } from "react";

const VOIPFire = () => {
  
    useEffect(() => { 
    }, [])
  
    return (
      <div className="App">
        UNDER CONSTRUCTION
      </div>
    );
  }
  
  export default VOIPFire;