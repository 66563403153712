import { Navigate, Outlet } from 'react-router-dom';

const useAuth = () => {
    const loggedIn = localStorage.getItem("user.loggedIn") === 'true';
    const accessLevel = parseInt(localStorage.getItem("user.accessLevel"), 10);

    return {
        isLoggedIn: loggedIn,
        accessLevel: accessLevel || 0
    };
};

const ProtectedRoutes = ({ requiredAccessLevel }) => {
    const { isLoggedIn, accessLevel } = useAuth();
    return isLoggedIn && accessLevel >= requiredAccessLevel ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;