import React from 'react';
import DigitalRadioContainer from '../DigitalRadioContainer';
import AnalogRadioContainer from '../AnalogRadioContainer';
import PhoneContainer from '../PhoneContainer';

const RadioPanel = ({ debug, connectedGroups, setConnectedGroups, deleteConnectedGroups, setActiveGroup }) => {

    const renderDigitalChannels = (slot, channel, radio) => {
        return <DigitalRadioContainer
            key={slot}
            debug={debug}
            channel={channel}
            radio={radio}
            slot={slot}
            addConnectedGroup={addConnectedGroup}
            deleteConnectedGroup={deleteConnectedGroup}
        />
    };

    const renderAnalogChannels = (slot, channel, radio) => {
        return <AnalogRadioContainer
            key={slot}
            debug={debug}
            channel={channel}
            radio={radio}
            slot={slot}
            addConnectedGroup={addConnectedGroup}
            deleteConnectedGroup={deleteConnectedGroup}
        />
    };

    const renderPhoneChannels = (slot, channel, radio) => {
        return <PhoneContainer
            key={slot}
            debug={debug}
            channel={channel}
            radio={radio}
            slot={slot}
            addConnectedGroup={addConnectedGroup}
            deleteConnectedGroup={deleteConnectedGroup}
        />
    };

    const addConnectedGroup = async (name, socket, deaf, mute) => {
        if (!connectedGroups[name]) {
            setConnectedGroups(prev => ({
                ...prev,
                [name]: { socketID: socket, deaf, mute }
            }));
        }
        return connectedGroups[name];
    };

    const deleteConnectedGroup = async (name) => {
        if (connectedGroups[name]) {
            delete connectedGroups[name];
        }
        return connectedGroups[name];
    };

    return (
        <>
            <div className="lardisHistory"></div>
            <div className="ContentWrapper">
                <div className="lardisDashboard">
                    <div className='title1'>Digitalfunk Dach</div>
                    <div className='title2'>Digitalfunk Mast</div>
                    <div className='title3'>Analogfunk</div>
                    {renderDigitalChannels("slot1", { transmissiontype: 1, name: "F_WTM_1" }, "Tetra A Dach")}
                    {renderDigitalChannels("slot2", { transmissiontype: 1, name: "F_WTM_2" }, "Tetra B Dach")}
                    {renderDigitalChannels("slot3", { transmissiontype: 1, name: "F_WTM_3" }, "Tetra C Dach")}
                    {renderDigitalChannels("slot4", { transmissiontype: 1, name: "R_WTM_1" }, "Tetra D Mast")}
                    {renderDigitalChannels("slot5", { transmissiontype: 1, name: "K_WTM_1" }, "Tetra E Mast")}
                    {renderDigitalChannels("slot6", { transmissiontype: 2, name: "307_F*" }, "Tetra F Mast")}
                    {renderAnalogChannels("slot7", { name: "2m1" }, "2m1")}
                    {renderAnalogChannels("slot8", { name: "4m1" }, "4m1")}
                    {renderPhoneChannels("slot9", { name: "11" }, "11")}
                </div>
            </div>
        </>
    );
};

export default RadioPanel;
