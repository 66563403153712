import { useState, useEffect } from "react";
import React from 'react';
import { useNavigate } from "react-router-dom";
import cfg from '../../frontendsettings.json';

const Login = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const logonUser = async () => {
    if (username !== "" && password !== "") {
      try {
        const response = await fetch(cfg.connection.backend.protocol + "://" + cfg.connection.backend.host + ":" + cfg.connection.backend.port + cfg.connection.backend.endpoints.login, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            email: username,
            password: password
          }),
          credentials: 'include',
        });

        if (response.ok) {
          // Handle successful login, e.g., save token, navigate to another page
          const sessionData = await response.json();
          console.log("Login successful", sessionData);
          localStorage.setItem("user.session", JSON.stringify(sessionData.session.cookie));
          localStorage.setItem("user.userId", sessionData.session.userId);
          localStorage.setItem("user.accessLevel", sessionData.session.user.accessLevel);
          localStorage.setItem("user.loggedIn", true);
          localStorage.setItem("user.token", sessionData.token);

          navigate("/dashboard"); // or any other page you want to redirect to
        } else {
          // Handle login failure, e.g., show error message
          const errorData = response;
          console.error("Login failed", errorData);
        }
      } catch (error) {
        console.error("Error during login", error);
      }
    }
  };

  useEffect(() => {
  }, [])

  return (
    <div className="login">
      <div id="logintop">
        <div id="loginheader">
          <h1>Anmelden</h1>
        </div>
      </div>
      <div className="form">
        <label>
          <span className="material-icons md-16">person</span>
        </label>
        <input
          type="text"
          placeholder="Username"
          onChange={(event) => {
            setUsername(event.target.value);
          }} />
        <label>
          <span className="material-icons md-16">key</span>
        </label>
        <input
          type="password"
          placeholder="Password"
          onChange={(event) => {
            setPassword(event.target.value);
          }} />
        Kein Account? <a onClick={() => navigate("/register")}>&nbsp;HIER&nbsp;</a> registrieren!
        <button onClick={logonUser}>Anmelden</button>
      </div>
    </div>
  );
}

export default Login;