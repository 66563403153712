import React, { useEffect, useState } from 'react';

const RadioFooter = () => {

    const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }));

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }));
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="lardisFooter">
            <div className="headset"><span className="fas fa-headset fa-xl" /></div>
            <div className="pushtotalk"></div>
            <div className="chat"><span className="fas fa-comment fa-xl" /></div>
            <div className="time">{currentTime}</div>
        </div>
    );
};

export default RadioFooter;


