import React from "react";
import Views from "./components/Views";

function App() {
  return (
      <>
        <Views />
      </>
  );
}

export default App;