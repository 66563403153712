import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import UnitAdmin from '../UnitAdmin/UnitAdmin';
import MemberAdmin from '../MemberAdmin/MemberAdmin';
import LogoutButton from '../Logout/Logout'; // Beispiel für die Logout-Button-Komponente

const useAuth = () => {
    const loggedIn = localStorage.getItem("user.loggedIn") === 'true';
    const accessLevel = parseInt(localStorage.getItem("user.accessLevel"), 10);

    return {
        isLoggedIn: loggedIn,
        accessLevel: accessLevel || 0
    };
};

const Dashboard = () => {
    const { accessLevel } = useAuth();

    const openLardisWindow = () => {
        const popupWidth = 1024; // Breite des Popup-Fensters in Pixeln
        const popupHeight = 768; // Höhe des Popup-Fensters in Pixeln
        const left = window.screen.width / 2 - popupWidth / 2;
        const top = window.screen.height / 2 - popupHeight / 2;

        // Popup-Fenster öffnen
        window.open('/lardis', '_blank', `width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}, resizable=no`);
    };

    const openDispatcherWindow = () => {
        const popupWidth = 1024; // Breite des Popup-Fensters in Pixeln
        const popupHeight = 768; // Höhe des Popup-Fensters in Pixeln
        const left = window.screen.width / 2 - popupWidth / 2;
        const top = window.screen.height / 2 - popupHeight / 2;

        // Popup-Fenster öffnen
        window.open('/dispatch', '_blank', `width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}, resizable=no`);
    };

    const handleLogout = () => {
        // Hier könntest du die Logout-Logik implementieren, z.B. Zustände zurücksetzen, Benutzer ausloggen usw.
        console.log("Logout clicked");
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-11">
                    <h1 className="mt-4 mb-4">Dashboard</h1>
                </div>
                <div className="col-md-1 mt-4 mb-4">
                    <LogoutButton onLogout={handleLogout} />
                </div>
            </div>
            {/* Dashboard-Kacheln */}
            <div className="row">
                {accessLevel >= 1 && (
                    <div className="col-md-6 mb-4">
                        <div className="card" onClick={openLardisWindow}>
                            <div className="card-body">
                                <h5 className="card-title">LARDIS</h5>
                                <p className="card-text">Virtuelles LARDIS-Desk öffnen</p>
                            </div>
                        </div>
                    </div>
                )}
                {accessLevel >= 2 && (
                    <div className="col-md-6 mb-4">
                        <div className="card" onClick={openDispatcherWindow}>
                            <div className="card-body">
                                <h5 className="card-title">Dispatcher</h5>
                                <p className="card-text">Verwalte die Teilnehmer als Leitstelle</p>
                            </div>
                        </div>
                    </div>
                )}
                {accessLevel >= 4 && (
                    <div className="col-md-6 mb-4">
                        <Link to="/unitadmin" className="card link-card">
                            <div className="card-body">
                                <h5 className="card-title">Datenpflege</h5>
                                <p className="card-text">Verwalte die Daten der Teilnehmer</p>
                            </div>
                        </Link>
                    </div>
                )}
                {accessLevel >= 8 && (
                    <div className="col-md-6 mb-4">
                        <Link to="/memberadmin" className="card link-card">
                            <div className="card-body">
                                <h5 className="card-title">Benutzerverwaltung</h5>
                                <p className="card-text">Verwalte die Teilnehmer</p>
                            </div>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Dashboard;
