import React, { useEffect, useState } from 'react';
import RadioHeader from './components/RadioHeader';
import RadioPanel from './components/RadioPanel';
import RadioFooter from './components/RadioFooter';

const Lardis = () => {

  const [activeGroup, setActiveGroup] = useState(localStorage.getItem('activeGroup') || 1);
  const token = localStorage.getItem('user.token');
  const [connectedGroups, setConnectedGroups] = useState({});
  const debug = false;

  const deleteConnectedGroup = (name) => {
    setConnectedGroups(prev => {
      const newGroups = { ...prev };
      delete newGroups[name];
      return newGroups;
    });
  };

  return (
    <div className="lardis-container">
      <RadioHeader />
      <RadioPanel
        debug={debug}
        connectedGroups={connectedGroups}
        setConnectedGroups={setConnectedGroups}
        deleteConnectedGroups={deleteConnectedGroup}
        setActiveGroup={setActiveGroup}
      />
      <RadioFooter />
    </div>
  );
};

export default Lardis;
