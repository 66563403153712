import React, { useEffect, useRef, useState } from 'react';
import RadioContainer from './components/RadioContainer';

const Dispatch = () => {

    const [activeGroup, setActiveGroup] = useState(localStorage.getItem('activeGroup') || 1);
    const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());
    const [leitstellen, setLeitstellen] = useState([]);
    const [selectedLeitstelle, setSelectedLeitstelle] = useState(localStorage.getItem('selectedLeitstelle') || '');
    const [radioGroups, setRadioGroups] = useState([]);
    const token = localStorage.getItem('user.token');
    const [connectedGroups, addConnectedGroups] = useState({});

    useEffect(() => {
        const fetchLeitstellen = async () => {
            try {
                const response = await fetch('https://api.bos-funkspiel.com/api/v1/dispatchingcenter', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                setLeitstellen(data.data); // Assuming the response contains a list of leitstellen
            } catch (error) {
                console.error('Error fetching leitstellen:', error);
            }
        };

        fetchLeitstellen();
    }, [token]);

    useEffect(() => {
        if (selectedLeitstelle) {
            const fetchRadioGroups = async () => {
                try {
                    const response = await fetch(`https://api.bos-funkspiel.com/api/v1/dispatchingcenter/${selectedLeitstelle}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    const data = await response.json();

                    setRadioGroups(data.radiogroup); // Assuming the response contains radio groups data
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };

            fetchRadioGroups();
        }
    }, [selectedLeitstelle, token]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date().toLocaleTimeString());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const handleLeitstelleChange = (event) => {
        const leitstelle = event.target.value;
        setSelectedLeitstelle(leitstelle);
        localStorage.setItem('selectedLeitstelle', leitstelle);
    };

    const handleTabClick = (groupNumber) => {
        setActiveGroup(groupNumber);
        localStorage.setItem('activeGroup', activeGroup + 1);
    };

    const renderChannels = (group) => {
        return group.channel.radiochannel.map(channel => (
            <RadioContainer
                key={channel.id}
                channelName={channel.name}
                addConnectedGroup={addConnectedGroup}
                deleteConnectedGroup={deleteConnectedGroup}
            />
        ));
    };

    const addConnectedGroup = async (channelName, socket, deaf, mute) => {
        if (!connectedGroups[channelName]) {
            addConnectedGroups(prev => ({
                ...prev,
                [channelName]: { socketID: socket, deaf, mute }
            }));
        }
        return connectedGroups[channelName];
    };

    const deleteConnectedGroup = (channelName) => {
        setAudioElements(prev => {
            const connectedGroups = { ...prev };
            delete connectedGroups[channelName];
            return connectedGroups;
        });
    };

    const openunitOverview = (dispatcherId) => {
        const popupWidth = 1210; // Breite des Popup-Fensters in Pixeln
        const popupHeight = 880; // Höhe des Popup-Fensters in Pixeln
        const left = window.screen.width / 2 - popupWidth / 2;
        const top = window.screen.height / 2 - popupHeight / 2;

        const popupWindow = window.open('/units', '_blank', `width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}, resizable=no`);
    };

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-secondary">
                <div className="navbar-brand">
                    {currentTime}
                </div>
                <div className="navbar-text text-white">
                    <select onChange={handleLeitstelleChange} className="form-control" value={selectedLeitstelle}>
                        <option value="">- - Leitstelle wählen - -</option>
                        {leitstellen.map((leitstelle) => (
                            <option key={leitstelle.id} value={leitstelle.id}>
                                {leitstelle.callname}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="navbar-collapse justify-content-center">
                    <ul className="nav nav-pills">
                        {radioGroups.map((group, index) => (
                            <li className="nav-item" key={index}>
                                <a className={`nav-link text-white ${activeGroup === index + 1 ? 'active' : ''}`} onClick={() => handleTabClick(index + 1)}>{group.name}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </nav>
            <div className="tab-content mt-4 container-fluid">
                {radioGroups.map((group, index) => (
                    <div key={index} className={`tab-pane ${activeGroup === index + 1 ? 'active' : 'fade'}`}>
                        <div className="row">
                            {renderChannels(group)}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Dispatch;
