import React, { useEffect, useState, useRef } from "react";
import { io } from "socket.io-client";

const DigitaldigitalRadioContainer = ({ debug, channel, radio, slot, addConnectedGroup, deleteConnectedGroup }) => {
    const [isDeaf, setIsDeaf] = useState(true);
    const [isMute, setIsMute] = useState(true);
    const [rx, setRX] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [hasChannel, setHasChannel] = useState(false);
    const [caller, setCaller] = useState("");
    const [transmissiontype, setTransmissionType] = useState("");
    const [audioElements, setAudioElements] = useState({});
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);
    const localStreamRef = useRef(null);
    const peerConnectionsRef = useRef({});
    const iceCandidatesQueueRef = useRef({});
    const socketRef = useRef(null);
    const iceServersRef = useRef([]);
    const usersRef = useRef([]);
    const isPushingToTalk = useRef(false);

    useEffect(() => {
        if (hasChannel) {
            switch (channel.transmissiontype) {
                case 1:
                    setTransmissionType("TMO");
                    break;
                case 2:
                    setTransmissionType("DMO");
                    break;
            }
        }
    }, [hasChannel, channel.transmissiontype]);

    useEffect(() => {
        if (isMute) {

        } else {

        }

        if (isDeaf) {

        } else {

        }
    }, [isMute, isDeaf]);

    useEffect(() => {
        if (channel.name) {
            setHasChannel(true);
            const start = async () => {
                try {
                    localStreamRef.current = await navigator.mediaDevices.getUserMedia({ audio: true });
                    socketRef.current = io('https://voice.bos-funkspiel.com');
                    socketRef.current.emit('join', channel.name);

                    socketRef.current.on('connect', () => {
                        addConnectedGroup(channel.name, socketRef.current.id, isDeaf, isMute);
                    });

                    socketRef.current.on('iceServers', (servers) => {
                        iceServersRef.current = servers;
                    });

                    socketRef.current.on('existingUsers', (users) => {
                        //console.log(`Vorhandene Benutzer in Gruppe "${channel.name}": ${users}`);
                        usersRef.current = users;
                        setInitialDataLoaded(true);
                    });

                    socketRef.current.on('user-joined', (userId) => {
                        if (initialDataLoaded) {
                            createPeerConnection(userId);
                        } else {
                            usersRef.current.push(userId);
                        }
                    });

                    socketRef.current.on('start-speaking', (data) => {
                        const { target, speaker, system } = data;
                        if (speaker != socketRef.current.id) {
                            setRX(true);
                            setCaller(system);
                        }
                    });

                    socketRef.current.on('stop-speaking', (data) => {
                        const { target, speaker } = data;
                        if (speaker != socketRef.current.id) {
                            setRX(false);
                            setCaller("");
                        }
                    });

                    socketRef.current.on('signal', async (data) => {
                        const { from, message } = data;
                        const peerConnection = peerConnectionsRef.current[from];
                        if (peerConnection) {
                            if (message.type === 'offer') {
                                await handleOffer(from, message);
                            } else if (message.type === 'answer') {
                                await handleAnswer(from, message);
                            } else if (message.candidate) {
                                handleCandidate(from, message);
                            }
                        } else {
                            console.log(`Peer-Verbindung nicht gefunden für ${from}`);
                        }
                    });

                    socketRef.current.on('user-disconnected', (userId) => {
                        removePeerConnection(userId);
                    });

                    socketRef.current.on('update-users', (users) => {
                        handleUpdatedUsers(users);
                    });
                } catch (error) {
                    console.error("Fehler beim Erwerben des lokalen Streams:", error);
                }
            };


            if (!debug) {
                start();
            }

        } else {
            setHasChannel(false);
        }

        return () => {
            // Cleanup-Funktion, um Verbindungen und Streams zu schließen
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
            Object.values(peerConnectionsRef.current).forEach(pc => pc.close());
            if (localStreamRef.current) {
                localStreamRef.current.getTracks().forEach(track => track.stop());
            }
        };
    }, []);

    useEffect(() => {
        if (initialDataLoaded) {
            usersRef.current.forEach(userId => {
                if (!peerConnectionsRef.current[userId]) {
                    createPeerConnection(userId);
                }
            });
        }
    }, [initialDataLoaded]);

    useEffect(() => {
        Object.keys(audioElements).forEach(userId => {
            const audioRef = audioElements[userId]?.ref;
            if (audioRef?.current && audioElements[userId]?.stream) {
                audioRef.current.srcObject = audioElements[userId].stream;
                audioRef.current.muted = isDeaf;
                audioRef.current.play().catch(/*error => console.error('Fehler beim Abspielen von Audio:', error)*/);
            }
        });
    }, [audioElements]);

    useEffect(() => {
        let timer;
        if (rx) {
            setIsActive(true);
        } else {
            timer = setTimeout(() => {
                setIsActive(false);
            }, 3000);
        }

        return () => clearTimeout(timer);
    }, [rx]);

    const createPeerConnection = (userId) => {
        const pc = new RTCPeerConnection({ iceServers: iceServersRef.current });

        pc.onicecandidate = (event) => {
            if (event.candidate) {
                socketRef.current.emit('signal', { target: userId, message: event.candidate });
            }
        };

        pc.ontrack = (event) => {
            const stream = event.streams[0];
            setAudioElements(prev => ({
                ...prev,
                [userId]: { ref: React.createRef(), stream }
            }));
        };

        pc.onconnectionstatechange = () => {
            if (pc.connectionState === 'disconnected' || pc.connectionState === 'failed') {
                removePeerConnection(userId);
            }
        };

        peerConnectionsRef.current[userId] = pc;
        iceCandidatesQueueRef.current[userId] = [];
    };

    const handleOffer = async (from, offer) => {
        const peerConnection = peerConnectionsRef.current[from];
        if (peerConnection.signalingState === 'stable' || peerConnection.signalingState === 'have-local-offer') {
            await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
            const answer = await peerConnection.createAnswer();
            await peerConnection.setLocalDescription(answer);
            socketRef.current.emit('signal', { target: from, message: peerConnection.localDescription });
            processQueuedCandidates(from);
        } else {
            //console.log('Peer-Verbindung ist nicht in einem Zustand, um ein Angebot zu akzeptieren.');
        }
    };

    const handleAnswer = async (from, answer) => {
        const peerConnection = peerConnectionsRef.current[from];
        if (peerConnection.signalingState === 'have-local-offer') {
            await peerConnection.setRemoteDescription(new RTCSessionDescription(answer));
            processQueuedCandidates(from);
        } else {
            //console.log('Peer-Verbindung ist nicht in einem Zustand, um eine Antwort zu akzeptieren.');
        }
    };

    const handleCandidate = (from, candidate) => {
        const peerConnection = peerConnectionsRef.current[from];
        if (peerConnection.remoteDescription && peerConnection.remoteDescription.type) {
            peerConnection.addIceCandidate(new RTCIceCandidate(candidate))
                .catch(error => console.error('Fehler beim Hinzufügen des ICE-Kandidaten:', error));
        } else {
            iceCandidatesQueueRef.current[from].push(candidate);
        }
    };

    const processQueuedCandidates = (userId) => {
        const peerConnection = peerConnectionsRef.current[userId];
        const queue = iceCandidatesQueueRef.current[userId];
        while (queue.length > 0) {
            const candidate = queue.shift();
            peerConnection.addIceCandidate(new RTCIceCandidate(candidate))
                .catch(error => console.error('Fehler beim Hinzufügen des ICE-Kandidaten aus der Warteschlange:', error));
        }
    };

    const removePeerConnection = (userId) => {
        if (peerConnectionsRef.current[userId]) {
            peerConnectionsRef.current[userId].close();
            delete peerConnectionsRef.current[userId];
            delete iceCandidatesQueueRef.current[userId];
            setAudioElements(prev => {
                const newAudioElements = { ...prev };
                delete newAudioElements[userId];
                return newAudioElements;
            });
        }
    };

    const handleUpdatedUsers = (users) => {
        const currentUsers = Object.keys(peerConnectionsRef.current);
        currentUsers.forEach(userId => {
            if (!users.includes(userId)) {
                removePeerConnection(userId);
            }
        });
        users.forEach(userId => {
            if (!peerConnectionsRef.current[userId]) {
                createPeerConnection(userId);
            }
        });
    };

    const handleDeaf = () => {
        setIsDeaf(prev => !prev);
        setIsMute(true);
        Object.values(peerConnectionsRef.current).forEach(pc => {
            pc.getReceivers().forEach(receiver => receiver.track.enabled = !isDeaf);
        });
    };



    const handleMute = () => {
        if (!isDeaf) {
            setIsMute(prev => !prev);
            Object.keys(peerConnectionsRef.current).forEach(userId => {
                const peerConnection = peerConnectionsRef.current[userId];
                if (peerConnection) {
                    peerConnection.getSenders().forEach(sender => {
                        if (sender.track && sender.track.kind === 'audio') {
                            peerConnection.removeTrack(sender);
                        }
                    });
                    if (peerConnection.signalingState === 'stable' || peerConnection.signalingState === 'have-local-offer') {
                        peerConnection.createOffer()
                            .then(offer => peerConnection.setLocalDescription(offer))
                            .then(() => {
                                socketRef.current.emit('signal', { target: userId, message: peerConnection.localDescription });
                            })
                            .catch(error => console.error('Fehler beim Erstellen des Angebots:', error));
                    }
                }
            });
        }
    };

    const handlePushToTalkDown = () => {
        if (!isMute) {
            isPushingToTalk.current = true;
            Object.keys(peerConnectionsRef.current).forEach(userId => {
                const peerConnection = peerConnectionsRef.current[userId];
                if (peerConnection && localStreamRef.current) {
                    localStreamRef.current.getTracks().forEach(track => {
                        if (!peerConnection.getSenders().some(sender => sender.track === track)) {
                            peerConnection.addTrack(track, localStreamRef.current);
                        }
                    });
                    if (peerConnection.signalingState === 'stable' || peerConnection.signalingState === 'have-local-offer') {
                        peerConnection.createOffer()
                            .then(offer => peerConnection.setLocalDescription(offer))
                            .then(() => {
                                socketRef.current.emit('signal', { target: userId, message: peerConnection.localDescription });
                            })
                            .catch(error => console.error('Fehler beim Erstellen des Angebots:', error));
                    }
                }
            });
            socketRef.current.emit('start-speaking', { target: channel.name, speaker: socketRef.current.id, system: "Leitstelle" });
        }
    };

    const handlePushToTalkUp = () => {
        if (!isMute) {
            isPushingToTalk.current = false;
            Object.keys(peerConnectionsRef.current).forEach(userId => {
                const peerConnection = peerConnectionsRef.current[userId];
                if (peerConnection) {
                    peerConnection.getSenders().forEach(sender => {
                        if (sender.track && sender.track.kind === 'audio') {
                            peerConnection.removeTrack(sender);
                        }
                    });
                    if (peerConnection.signalingState === 'stable' || peerConnection.signalingState === 'have-local-offer') {
                        peerConnection.createOffer()
                            .then(offer => peerConnection.setLocalDescription(offer))
                            .then(() => {
                                socketRef.current.emit('signal', { target: userId, message: peerConnection.localDescription });
                            })
                            .catch(error => console.error('Fehler beim Erstellen des Angebots:', error));
                    }
                }
            });
            socketRef.current.emit('stop-speaking', { target: channel.name, speaker: socketRef.current.id, system: "Leitstelle" });
        }
    };

    if (slot) {
        return (
            <div className={rx ? `${slot} digitalRadioContainer listening` : `${slot} digitalRadioContainer`}>
                <div className="digitalRadioHeader">
                    <div className="digitalRadioName">{radio}</div>
                    <div className="digitalRadioCrypt"><span className="fas fa-lock" /></div>
                    {channel.transmissiontype === 1 ? <div className="digitalRadioSignal"><span className="fas fa-signal" /></div> : hasChannel ? <div className="digitalRadioSignal"></div> : <div className="digitalRadioSignal"><span className="fas fa-triangle-exclamation" /></div>}
                    <div className="digitalRadioMode">{transmissiontype}</div>
                </div>
                <div className="digitalRadioBody ">
                    <div className="digitalRadioMuted"><span className="fas fa-volume-xmark" /></div>
                    <div className="digitalRadioActive">
                        {isActive ? 'Gruppe aktiv' : ''}
                    </div>
                    <div className="digitalRadioChannel">{hasChannel ? channel.name : 'Kein Signal'}</div>
                    <div className="digitalRadioCaller">{caller}</div>
                </div>
                {Object.keys(audioElements).map(userId => (
                    <audio className="hiddenAudio"
                        key={userId}
                        ref={audioElements[userId].ref}
                        muted={isDeaf}
                        autoPlay
                        controls
                    />
                ))}
            </div>
        );
    } else {
        return (<></>);
    }

};

export default DigitaldigitalRadioContainer;
