import React from "react";
import { useState, useEffect, useRef } from "react";

const RadioSignalIndicator = ({rx, tx}) => {
    
    const [rxBG, setRxBG] = useState(null);
    const [txBG, setTxBG] = useState(null);

    useEffect(() => {

        if(rx){
            setRxBG("limegreen");
        } else{
            setRxBG("red");
        }

        if(tx){
            setTxBG("limegreen");
        } else{
            setTxBG("red");
        }

    },[rx, tx]);

    return (
        <div className="radioStatus">
            <div className="radioSignal" style={{ backgroundColor: rxBG }}></div>
            <div className="radioSignal" style={{ backgroundColor: txBG }}></div>
            <div className="signalDescRx">RX</div>
            <div className="signalDescTx">TX</div>
        </div>
    );
};

export default RadioSignalIndicator;