import React from 'react';

function DefaultLayout({ children }) {

    return (
        <>
            <head>
                <meta charSet="utf-8" />
                <link rel="icon" href="favicon.ico" />
                <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta name="description" content="react-app" />
                <link rel="apple-touch-icon" href="logo192.png" />
                <link rel="manifest" href="manifest.json" />
                <link rel="stylesheet" type="text/css" href="css/bootstrap.css"/>
                <link rel="stylesheet" type="text/css" href="css/app.css"/>
                <link rel="stylesheet" type="text/css" href="css/memberadmin.css"/>
                <link rel="stylesheet" type="text/css" href="css/unitadmin.css"/>
                <title>Funkspiel</title>
            </head>
            <body>
                <noscript>You need to enable JavaScript to run this app.</noscript>
                {children}
            </body>
        </>
    );
};

export default DefaultLayout;