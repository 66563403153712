import React, { useEffect, useState } from 'react';

const UnitAdmin = () => {
    const [units, setUnits] = useState([]);
    const token = localStorage.getItem('user.token');

    useEffect(() => {
        const fetchUnits = async () => {
            try {
                const response = await fetch('https://api.bos-funkspiel.com/api/v1/unit', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                setUnits(data.data); // Assuming the response contains a list of units
            } catch (error) {
                console.error('Error fetching units:', error);
            }
        };

        fetchUnits();
    }, [token]);

    return (
        <div>
            <h1>Unit Administration</h1>
            <ul>
                {units.map(unit => (
                    <li key={unit.id}>{unit.name}</li>
                ))}
            </ul>
        </div>
    );
};

export default UnitAdmin;